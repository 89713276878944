import React, { useEffect, useState } from "react";
import { FaBeer, FaBuilding, FaChartArea, FaClock, FaWifi } from 'react-icons/fa'; // Ícono de Font Awesome
import { Link, useParams } from "react-router-dom";

import image1 from "../../assets/images/listing/1.jpg";
import image2 from "../../assets/images/listing/2.jpg";
import image3 from "../../assets/images/listing/3.jpg";
import image4 from "../../assets/images/listing/4.jpg";

import DetailSidebar from "../../components/detail-sidebar";
import Switcher from "../../components/switcher";

import { FiCamera, FiMapPin, FiUsers } from "../../assets/icons/vander";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import { packages } from "../../data/data";
import { HttpConsultas } from "../../apis/HttpConsultas";
import { FaElevator } from "react-icons/fa6";

export default function Alquiler() {

  let images = [image1, image2, image3, image4];
  let [photoIndex, setActiveIndex] = useState(0);
  let [isOpen, setOpen] = useState(false);
  const [horariosLaborales, setHorariosLaborales] = useState(null); // Nuevo estado para almacenar horarios_laborales
  const [loading, setLoading] = useState(true); // Estado de carga
  const [data, setData] = useState(null)

  let handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };
  let params = useParams();
  let id = params.id;
  //let data = packages.find((item) => item.id === parseInt(id)); // Asegúrate de que el ID coincida con el tipo de dato


  useEffect(() => {

    setLoading(true); // Iniciar el estado de carga
    HttpConsultas.ObtenerEspacio(id)
      .then((response) => {
        console.log(55, response);
        if (response.status === 200) {
          const horarios = JSON.parse(response.data.horarios_laborales);
          setHorariosLaborales(horarios); // Guardar horarios_laborales en el estado
          setData(response.data)
        }
        setLoading(false); // Marcar que la solicitud ha finalizado
      });
  }, [id]);

  return (
    <>
      <section className="relative table w-full items-center py-24 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="text-3xl leading-normal tracking-wider font-semibold text-white">
              {data?.nombre ? data.nombre : `????????`}
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Inicio</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Alquiler
            </li>
          </ul>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-6">
            <div className="lg:col-span-8 md:col-span-7">
              <div className="grid grid-cols-12 gap-4">
                <div className="md:col-span-8 col-span-7">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data?.galeria[0]}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(0)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-4 col-span-5">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data?.galeria[1]}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(1)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-4 col-span-5">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data?.galeria[2]}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(2)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="md:col-span-8 col-span-7">
                  <div className="group relative overflow-hidden rounded shadow dark:shadow-gray-800">
                    <img
                      src={data?.galeria[3]}
                      className="w-full lg:h-60 md:h-44 h-48 object-cover"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/70 duration-500 ease-in-out"></div>
                    <div className="absolute top-1/2 -translate-y-1/2 start-0 end-0 text-center opacity-0 group-hover:opacity-100 duration-500">
                      <Link
                        to="#"
                        onClick={() => handleCLick(3)}
                        className="inline-flex justify-center items-center size-9 bg-red-500 text-white rounded-full lightbox"
                      >
                        <FiCamera className="size-4 align-middle"></FiCamera>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <h5 className="text-2xl font-semibold mt-5">
                {data?.amount ? data.amount : "0"} pts por hora
              </h5>
              <h5 className="text-2xl font-semibold mt-5">
                {data?.nombre ? data.nombre : "No encontrado"}
              </h5>
              <p className="flex items-center text-slate-400 font-medium mt-2">
                <FiMapPin className="size-4 me-1"></FiMapPin>{" "}
                {data?.ciudad ? data.ciudad : "No encontrado"}
              </p>

              <ul className="list-none">

                {data?.pisos && (
                  <li
                    className="inline-flex items-center me-5 mt-5"
                  >
                    <FaBuilding className="size-6 stroke-[1.5] text-red-500" />
                    <div className="ms-3">
                      <p className="font-medium">Piso</p>
                      <span className="text-slate-400 font-medium text-sm">
                        {data?.pisos}
                      </span>
                    </div>
                  </li>
                )}
                <li
                  className="inline-flex items-center me-5 mt-5"
                >
                  <FaWifi className="size-6 stroke-[1.5] text-red-500" />
                  <div className="ms-3">
                    <p className="font-medium">Internet</p>
                    <span className="text-slate-400 font-medium text-sm">
                      {data?.internet === 1 ? "Si" : "No"}
                    </span>
                  </div>
                </li>
                <li
                  className="inline-flex items-center me-5 mt-5"
                >
                  <FaClock className="size-6 stroke-[1.5] text-red-500" />
                  <div className="ms-3">
                    <p className="font-medium">Sala de Espera</p>
                    <span className="text-slate-400 font-medium text-sm">
                      {data?.sala_espera === 1 ? "Si" : "No"}
                    </span>
                  </div>
                </li>
                <li
                  className="inline-flex items-center me-5 mt-5"
                >
                  <FaChartArea className="size-6 stroke-[1.5] text-red-500" />
                  <div className="ms-3">
                    <p className="font-medium">Area</p>
                    <span className="text-slate-400 font-medium text-sm">
                      {data?.dimensiones}
                    </span>
                  </div>
                </li>
                <li
                  className="inline-flex items-center me-5 mt-5"
                >
                  <FaElevator className="size-6 stroke-[1.5] text-red-500" />
                  <div className="ms-3">
                    <p className="font-medium">Ascensor</p>
                    <span className="text-slate-400 font-medium text-sm">
                      {data?.ascensor === 1 ? "Si" : "No"}
                    </span>
                  </div>
                </li>
              </ul>

              <div className="mt-6">
                <h5 className="text-lg font-semibold">
                  Descripción del Espacio:
                </h5>
                {
                  data?.descripcion && (
                    <div
                      dangerouslySetInnerHTML={{ __html: data.descripcion }}
                    ></div>
                  )
                }
              </div>

            </div>

            {!loading && (
              <DetailSidebar horariosLaborales={horariosLaborales} />
            )}
          </div>
        </div>
      </section>
      <Switcher />
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setActiveIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setActiveIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
}
